import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import Layout from '../components/Layout';
import PaginatedRoll from '../components/PaginatedRoll';
import Title from '../components/Title';
import constants from '../constants';

export default props => {
  const { data } = props;
  return (
    <Layout>
      <Helmet titleTemplate="%s | ヒナタビ">
        <title>最新の記事一覧</title>
        <meta
          name="description"
          content="神社／パワースポット検索サイト・ヒナタビの神社やパワースポットに関する最新の記事一覧です"
        />
      </Helmet>
      <Title>最新の記事一覧</Title>
      <PaginatedRoll
        type={constants.articleTypes.blog}
        data={data}
        pageContext={props.pageContext}
      />
    </Layout>
  );
};

export const paginatedBlogQuery = graphql`
  query PaginatedBlogRollQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            templateKey
            date(formatString: "YYYY.MM.DD")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
